/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IInfoTemplate,
  IListChannel,
  IPropsScripts,
  ListChannel,
} from '../../../models/channels/channel';

interface IIntegrationQRSlice {
  listChannel: ListChannel;
  channelsWhatsapp: IListChannel[];
  idActiveChannel: string;
  idChannel: string;
  isloading: boolean;
  error: string | null;
  scriptsBuilder: IPropsScripts;
  statusChannel: boolean;
  modalHandling: boolean;
  selectedChannel: string;
  selectedComponent: number;
  theNumberExists: boolean;
}

const initialState: IIntegrationQRSlice = {
  listChannel: {} as ListChannel,
  channelsWhatsapp: [],
  idChannel: '',
  idActiveChannel: '',
  isloading: false,
  error: null,
  scriptsBuilder: {} as IPropsScripts,
  statusChannel: false,
  modalHandling: false,
  selectedChannel: '',
  selectedComponent: 2,
  theNumberExists: false,
};

export const listChannelStore = createSlice({
  name: 'listChannelState',
  initialState,
  reducers: {
    setlistChannel: (
      state: { listChannel: ListChannel },
      action: PayloadAction<ListChannel>,
    ) => {
      state.listChannel = action.payload;
    },
    setChannelWhatsapp: (
      state: { channelsWhatsapp: IListChannel[] },
      action: PayloadAction<IListChannel[]>,
    ) => {
      state.channelsWhatsapp = action.payload;
    },
    setUpdateChannelWhatsapp: (state, action: PayloadAction<IInfoTemplate>) => {
      const indexChannel = state.channelsWhatsapp?.findIndex(
        (item) => item._id === action.payload.id,
      );
      if (indexChannel !== -1) {
        state.channelsWhatsapp[indexChannel].templates =
          action.payload.template;
      }
    },
    setIdChannel: (
      state: { idChannel: string },
      action: PayloadAction<string>,
    ) => {
      state.idChannel = action.payload;
    },
    setScript: (
      state: { scriptsBuilder: IPropsScripts },
      action: PayloadAction<IPropsScripts>,
    ) => {
      state.scriptsBuilder = action.payload;
    },
    setStatusChannel: (
      state: { statusChannel: boolean },
      action: PayloadAction<boolean>,
    ) => {
      state.statusChannel = action.payload;
    },
    setIdActiveChannel: (
      state: { idActiveChannel: string },
      action: PayloadAction<string>,
    ) => {
      state.idActiveChannel = action.payload;
    },
    setOpenModalChannel: (state, action: PayloadAction<boolean>) => {
      state.modalHandling = action.payload;
    },
    setSeletedChannel: (state, action: PayloadAction<string>) => {
      state.selectedChannel = action.payload;
    },
    setSeletedComponentDialog: (state, action: PayloadAction<number>) => {
      state.selectedComponent = action.payload;
    },
    setNumberExists: (state, action: PayloadAction<boolean>) => {
      state.theNumberExists = action.payload;
    },
  },
});

export const {
  setlistChannel,
  setIdChannel,
  setScript,
  setStatusChannel,
  setIdActiveChannel,
  setChannelWhatsapp,
  setUpdateChannelWhatsapp,
  setOpenModalChannel,
  setSeletedChannel,
  setSeletedComponentDialog,
  setNumberExists,
} = listChannelStore.actions;
export default listChannelStore.reducer;
