import { User } from '../users/user';

export enum IProviderWhatsapp {
  DIALOG360 = 'DIALOG360',
  GUPSHUP = 'GUPSHUP',
}
export enum StatusTemplate {
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
}

export type Channel = {
  _id: string;
  name: string;
  type: string;
  status: string;
  assignedUsers: User[];
  createdAt: Date;
  updatedAt: Date;
};

export type IOnboarding360Dialog = {
  clientId: any;
  channelId: any;
};

export type ListChannel = {
  facebook: IFacebook;
  gupshup: IGupshup;
  channels: IListChannel[];
  webchat: IPropsWebChat;
  officialWhatsApp: IOfficialWhatsApp;
  unofficialWhatsApp: IUnOfficialWhatsApp;
  instagram: IPropsInstagram;
  availableChannels: string[];
};

export type IListChannel = {
  accessToken: string;
  image: string;
  isActive: boolean;
  pageId: string;
  pageName: string;
  type: string;
  _id: string;
  phoneNumber: string;
  name?: string;
  webchatName?: string;
  providerName: string;
  igName?: string;
  webchatScripts?: IPropsScripts;
  templates?: IPropsTemplates[];
};

export type IGupshup = {
  apiKey: string;
  isActive: boolean;
  image: string;
  phoneNumber: string;
  providerName: string;
  _id: string;
};

export type IFacebook = {
  accessToken: string;
  image: string;
  pageId: string;
  pageName: string;
  _id: string;
  isActive: boolean;
};

export type IOfficialWhatsApp = {
  apiKey: string;
  isActive: boolean;
  image: string;
  phoneNumber: string;
  providerName: string;
  _id: string;
};

export type IUnOfficialWhatsApp = {
  device: string;
  isActive: boolean;
  image: string;
  phoneNumber: string;
  providerName: string;
  _id: string;
};

export type IPropsOfficialWhatsapp = {
  providerName: IProviderWhatsapp;
  apiKey: string;
  phoneNumber: string;
  isActive?: boolean;
  appName?: string;
  appId?: string;
  name?: string;
};

export type IPropsWebChat = {
  name: string;
  description: string;
  avatar: string;
  isActive: boolean;
  _id: string;
  webchatScripts: IPropsScripts;
};

export type IPropsScripts = {
  js: string;
  css: string;
  div: string;
};

export type IWebChat = {
  name: string;
  description: string;
  primaryColor: string;
  secondaryColor: string;
  avatar: string;
  animation: boolean;
  greetingMessage: string;
  notificationSound: string;
  activeSound: boolean;
};

export type IPropsInstagram = {
  _id: string;
  image: string;
  igName: string;
  igUsername: string;
  accessToken: string;
  isActive: boolean;
};

export type IPropsUnOfficialWhatsapp = {
  success: boolean;
  code: number;
  result: string;
};
export interface IPropsChannel {
  name: string;
  clientId: string;
  _id?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface IchannelId {
  channelId: string;
}

export interface IPostTemplate {
  phoneNumber: string;
  code: string;
  id: string;
  name: string;
  source: string;
  mediaUrl?: string;
  type?: string;
  components: IComponentsProps[];
  idx?: number;
}

export interface IPropsTemplates {
  category: string;
  id: string;
  code: string;
  name: string;
  status: StatusTemplate;
  type: string;
  mediaUrl?: string;
  components?: IComponentsProps[];
}

export interface IInfoTemplate {
  id: string;
  template: IPropsTemplates[];
}

export interface IComponentsProps {
  type: ITypeVars | string;
  variables: string[];
  values: string[] | [];
  format?: string;
  text?: string;
  buttons?: ButtonsVars[];
  example?: UrlImageVars;
}

export interface UrlImageVars {
  header_handle: string[];
}

export interface ButtonsVars {
  type: string;
  text: string;
}
export const enum ITypeVars {
  BODY = 'BODY',
  HEADER = 'HEADER',
  FOOTER = 'FOOTER',
  BUTTONS = 'BUTTONS',
}
