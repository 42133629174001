/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Review } from '../../../models/chat/chat';
import {
  IConversationAverage,
  IEfficientChat,
  IService,
} from '../../../models/statistics/statistics';

interface IReviewSlice {
  reviewChats: Review[];
  averageConversations: IConversationAverage;
  chatsDuration: IConversationAverage;
  serviceLevel: IService;
  effectiveness: IEfficientChat;
  isloading: boolean;
  reviewByAgent: string;
  datePicker: string;
  error: string | null;
}

const initialState: IReviewSlice = {
  reviewChats: [],
  averageConversations: {} as IConversationAverage,
  chatsDuration: {} as IConversationAverage,
  serviceLevel: {} as IService,
  effectiveness: {} as IEfficientChat,
  isloading: false,
  reviewByAgent: '',
  datePicker: '',
  error: null,
};

export const chatContainerReviewStore = createSlice({
  name: 'chatContainerReviewState',
  initialState,
  reducers: {
    setReviewChatsFinished: (
      state: { reviewChats: Review[] },
      action: PayloadAction<Review[]>,
    ) => {
      state.reviewChats = action.payload;
    },
    setReviewByAgent: (
      state: { reviewByAgent: string },
      action: PayloadAction<string>,
    ) => {
      state.reviewByAgent = action.payload;
    },
    setReviewDatePicker: (
      state: { datePicker: string },
      action: PayloadAction<string>,
    ) => {
      state.datePicker = action.payload;
    },
    setAverageConversations: (
      state,
      action: PayloadAction<IConversationAverage>,
    ) => {
      state.averageConversations = action.payload;
    },
    setChatsDuration: (state, action: PayloadAction<IConversationAverage>) => {
      state.chatsDuration = action.payload;
    },
    setServiceLevel: (state, action: PayloadAction<IService>) => {
      state.serviceLevel = action.payload;
    },
    setEfficiency: (state, action: PayloadAction<IEfficientChat>) => {
      state.effectiveness = action.payload;
    },
  },
});

export const {
  setReviewChatsFinished,
  setReviewByAgent,
  setReviewDatePicker,
  setAverageConversations,
  setChatsDuration,
  setServiceLevel,
  setEfficiency,
} = chatContainerReviewStore.actions;
export default chatContainerReviewStore.reducer;
