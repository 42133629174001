/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IMessageEdit,
  IPropsCustomMessage,
} from '../../../models/setting/setting';

interface ILibrarySlice {
  libraryMessage: IPropsCustomMessage;
  isLoading: boolean;
  observeChange: IMessageEdit;
  error: string | null;
}

const initialState: ILibrarySlice = {
  libraryMessage: {} as IPropsCustomMessage,
  observeChange: {} as IMessageEdit,
  isLoading: false,
  error: null,
};

export const LibraryMessageStore = createSlice({
  name: 'LibraryMessageState',
  initialState,
  reducers: {
    setLibraryMessage: (
      state: { libraryMessage: IPropsCustomMessage },
      action: PayloadAction<IPropsCustomMessage>,
    ) => {
      state.libraryMessage = action.payload;
    },
    setMessageEdit: (state, action: PayloadAction<IMessageEdit>) => {
      state.observeChange = action.payload;
    },
  },
});

export const { setLibraryMessage, setMessageEdit } = LibraryMessageStore.actions;
export default LibraryMessageStore.reducer;
