/* eslint-disable no-multi-assign */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { baseRestApi } from '../../../api/base';
import {
  Chat,
  IChatFinished,
  IPropsChatFinished,
  Review,
} from '../../../models/chat/chat';

export const getChatsByPeriod = createAsyncThunk(
  'dashboardFilterInState/getChatsByPeriod',
  async (date1: string) => {
    const response = await baseRestApi.get<Chat[]>(
      `chats/statistics/finishedChatsCount/${date1}`,
    );
    if (response.success === false) {
      return [];
    }
    return response;
  },
);

interface DashboardFilterSliceInterface {
  chatsByPeriod: IPropsChatFinished[];
  todayFinishedChats: Chat[];
  todayPendingChats: Chat[];
  todayOnConversationChats: Chat[];
  reviewChats: Review[];
  loading: boolean;
  dashboardFilterDate: string;
  dateName: string;
}

const initialState: DashboardFilterSliceInterface = {
  chatsByPeriod: [],
  todayFinishedChats: [],
  todayPendingChats: [],
  todayOnConversationChats: [],
  reviewChats: [],
  loading: false,
  dashboardFilterDate: new Date().toISOString(),
  dateName: 'Hoy',
};

export const dashboardFilterInState = createSlice({
  name: 'dashboardFilterInState',
  initialState,
  reducers: {
    setDashboardFilterInState: (
      state: { dashboardFilterDate: string },
      action: PayloadAction<string>,
    ) => {
      state.dashboardFilterDate = action.payload;
    },
    setChatsByPeriod: (
      state: {
        chatsByPeriod: IPropsChatFinished[];
      },
      action: PayloadAction<IPropsChatFinished[]>,
    ) => {
      state.chatsByPeriod = action.payload;
    },
    setUpdateByPeriod: (state, action: PayloadAction<IChatFinished>) => {
      const chatFinished = state.chatsByPeriod?.filter(
        (i) => i._id === action.payload._id,
      )[0];
      const cloneArray = state.chatsByPeriod;
      if (chatFinished) {
        const index = cloneArray.indexOf(chatFinished);
        state.chatsByPeriod[index].total = state.chatsByPeriod[
          index
        ].total += 1;
        if (action.payload.status === 'SATISFACTORY') {
          state.chatsByPeriod[index].satisfactory = state.chatsByPeriod[
            index
          ].satisfactory += 1;
        } else {
          state.chatsByPeriod[index].satisfactory = state.chatsByPeriod[
            index
          ].unsatisfactory += 1;
        }
      }
    },
    setNameOfSelectedDateToFilter: (
      state: { dateName: string },
      action: PayloadAction<string>,
    ) => {
      state.dateName = action.payload;
    },
    setSortedChatsByPeriod: (state) => {
      state.chatsByPeriod = state.chatsByPeriod.sort(
        (a: { total: number }, b: { total: number }) =>
          a.total < b.total ? 1 : -1,
      );
    },
  },
  extraReducers: {
    [getChatsByPeriod.pending.type]: (state) => {
      state.loading = true;
    },
    [getChatsByPeriod.fulfilled.type]: (
      state,
      action: PayloadAction<IPropsChatFinished[]>,
    ) => {
      state.chatsByPeriod = action.payload;
      state.loading = false;
    },
    [getChatsByPeriod.rejected.type]: (state) => {
      state.loading = false;
    },
  },
});

export const {
  setDashboardFilterInState,
  setUpdateByPeriod,
  setChatsByPeriod,
  setSortedChatsByPeriod,
  setNameOfSelectedDateToFilter,
} = dashboardFilterInState.actions;

export default dashboardFilterInState.reducer;
