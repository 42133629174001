/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LiveChatSliceInterface {
  tagSelectedToTransferChat: string;
}

const initialState: LiveChatSliceInterface = {
  tagSelectedToTransferChat: '',
};

export const tagSelectedToTransferChat = createSlice({
  name: 'tagSelectedToTransferChat',
  initialState,
  reducers: {
    setTagSelectedToTransferChat: (
      state: { tagSelectedToTransferChat: string },
      action: PayloadAction<string>,
    ) => {
      state.tagSelectedToTransferChat = action.payload;
    },
  },
});

export const { setTagSelectedToTransferChat } =
  tagSelectedToTransferChat.actions;
export default tagSelectedToTransferChat.reducer;
