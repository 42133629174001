/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tag } from '../../../models/tags/tag';

interface IUserSlice {
  usersContainerTag: Tag[];
  isloading: boolean;
  error: string | null;
}

const initialState: IUserSlice = {
  usersContainerTag: [],
  isloading: false,
  error: null,
};

export const userContainerTagStore = createSlice({
  name: 'userContainerTagState',
  initialState,
  reducers: {
    setloading: (
      state: { isloading: boolean },
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isloading = payload;
    },
    setDataInfoTag: (
      state: { usersContainerTag: Tag[] },
      action: PayloadAction<Tag[]>,
    ) => {
      state.usersContainerTag = action.payload;
    },
  },
});

export const { setDataInfoTag, setloading } = userContainerTagStore.actions;
export default userContainerTagStore.reducer;
