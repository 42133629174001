/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IContactInfo,
  IPropsContacts,
} from '../../../models/contacts/contacts';
import { IPostTemplate, ITypeVars } from '../../../models/channels/channel';
import { IValue } from './contacts.interface';

interface IContactsSlice {
  dataContacts: IPropsContacts[];
  updateContact: IContactInfo;
  contactById: string;
  template: IPostTemplate;
  isloading: boolean;
  error: string | null;
}

const initialState: IContactsSlice = {
  dataContacts: [],
  updateContact: {} as IContactInfo,
  template: {} as IPostTemplate,
  contactById: '',
  isloading: false,
  error: null,
};

export const ContactsInfoStore = createSlice({
  name: 'contactsInfoState',
  initialState,
  reducers: {
    setInfoContacts: (
      state: { dataContacts: IPropsContacts[] },
      action: PayloadAction<IPropsContacts[]>,
    ) => {
      state.dataContacts = action.payload;
    },
    setUpdateContacts: (
      state: { updateContact: IContactInfo },
      action: PayloadAction<IContactInfo>,
    ) => {
      state.updateContact = action.payload;
    },
    setContactById: (
      state: { contactById: string },
      action: PayloadAction<string>,
    ) => {
      state.contactById = action.payload;
    },
    setTemplate: (
      state: { template: IPostTemplate },
      action: PayloadAction<IPostTemplate>,
    ) => {
      state.template = action.payload;
    },
    setBodyVariables: (state, action: PayloadAction<IValue>) => {
      const positionBody = state.template.components.findIndex(
        (component) => component.type === ITypeVars.BODY,
      );
      if (positionBody !== -1) {
        state.template.components[positionBody].values[action.payload.index] =
          action.payload.value;
      }
    },
    setHeaderVariables: (state, action: PayloadAction<IValue>) => {
      const positionHeader = state.template.components.findIndex(
        (component) => component.type === ITypeVars.HEADER,
      );
      if (positionHeader !== -1) {
        state.template.components[positionHeader].values[action.payload.index] =
          action.payload.value;
      }
    },
  },
});

export const {
  setInfoContacts,
  setUpdateContacts,
  setContactById,
  setTemplate,
  setBodyVariables,
  setHeaderVariables,
} = ContactsInfoStore.actions;
export default ContactsInfoStore.reducer;
