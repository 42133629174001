/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Chat } from '../../../models/chat/chat';

interface HistorySliceInterface {
  clientId: string;
  channelId: string;
  chatHistory: Chat[];
  hasHistory: boolean;
  pendingSession: boolean;
  chatHistoryPages: {
    chats: Chat[];
    total: number;
  };
}

const initialState: HistorySliceInterface = {
  clientId: '',
  channelId: '',
  chatHistory: [],
  chatHistoryPages: {
    chats: [],
    total: 0
  },
  hasHistory: false,
  pendingSession: false,
};

export const chatsHistoryStore = createSlice({
  name: 'chatsHistoryState',
  initialState,
  reducers: {
    setChatsIdClient: (
      state: { clientId: string },
      action: PayloadAction<string>,
    ) => {
      state.clientId = action.payload;
    },
    setChatsIdChannel: (
      state: { channelId: string },
      action: PayloadAction<string>,
    ) => {
      state.channelId = action.payload;
    },
    setChatsHistory: (
      state: { chatHistory: Chat[] },
      action: PayloadAction<Chat[]>,
    ) => {
      state.chatHistory = action.payload;
    },
    setChatHistoryPage: (state: {
      chatHistoryPages: {
        chats: Chat[];
        total: number;
      }
    }, action: PayloadAction<{
      chats: Chat[];
      total: number;
    }>) => {
        state.chatHistoryPages = action.payload;
    },
    setNewPage: (state: {
      chatHistoryPages: {
        chats: Chat[];
        total: number;
      }
    }, action: PayloadAction<Chat[]>) => {
      action.payload.forEach((item) => {
        if (!state.chatHistoryPages.chats.some((ele) => ele._id.includes(item._id))) {
          state.chatHistoryPages.chats = [...state.chatHistoryPages.chats, item];
        }
      })
    },
    setChatsHasHistory: (
      state: { hasHistory: boolean },
      action: PayloadAction<boolean>,
    ) => {
      state.hasHistory = action.payload;
    },
    setPendingSession: (
      state: { pendingSession: boolean },
      action: PayloadAction<boolean>,
    ) => {
      state.pendingSession = action.payload;
    },
  },
});

export const {
  setChatsIdClient,
  setChatsIdChannel,
  setChatsHistory,
  setChatsHasHistory,
  setPendingSession,
  setChatHistoryPage,
  setNewPage,
} = chatsHistoryStore.actions;
export default chatsHistoryStore.reducer;
