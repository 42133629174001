/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Chat, Message } from '../../../models/chat/chat';

export interface LiveChatSliceInterface {
  chatsPendings: Chat[];
  pageSplitting: Chat[];
  loadingPending: boolean;
  // allChatsPage: IPageChat[];
  // chatsByPage: IPageChat;
}

const initialState: LiveChatSliceInterface = {
  chatsPendings: [],
  pageSplitting: [],
  loadingPending: false,
  // allChatsPage: [],
  // chatsByPage: {} as IPageChat,
};

export const chatsPendingsToState = createSlice({
  name: 'chatsPendingsToState',
  initialState,
  reducers: {
    setChatsPendings: (
      state: { chatsPendings: Chat[] },
      action: PayloadAction<Chat[]>,
    ) => {
      state.chatsPendings = action.payload;
    },
    setOneChatPending: (
      state: { chatsPendings: Chat[] },
      action: PayloadAction<Chat>,
    ) => {
      if (
        state?.chatsPendings?.find((chat) => chat._id === action.payload._id)
      ) {
        state.chatsPendings = state.chatsPendings.filter(
          (chat) => chat._id !== action.payload._id,
        );
        state.chatsPendings = [
          (state.chatsPendings[
            state.chatsPendings?.findIndex(
              (chat) => chat._id === action.payload._id,
            )
          ] = action.payload),
          ...state.chatsPendings,
        ];
      } else {
        state.chatsPendings = [action.payload, ...state.chatsPendings];
      }
    },
    setloadingPending: (state, action: PayloadAction<boolean>) => {
      state.loadingPending = action.payload;
    },
    setNewMessagePending: (state: { chatsPendings: Chat[] }, action: PayloadAction<{
      chatId: string;
      message: Message;
    }>) => {
      state.chatsPendings = state.chatsPendings.map((chat: Chat) => {
        if (chat._id === action.payload.chatId) {
          const exisMessage = state.chatsPendings.find((item) =>
            item.messages.some(msg => msg._id === action.payload.message._id)
          );
          if (!exisMessage) {
            chat.messages = [...chat?.messages, action.payload.message];
          }
        }
        return chat;
      });
    },
    setPageSplitting: (state, action: PayloadAction<number>) => {
      const numPage = 6;
      const totalChats = state.chatsPendings?.length ?? 0;
      const totalPage = Math.ceil(totalChats / numPage);
      const startIndex = (action.payload - 1) * numPage;
      const endIndex = startIndex + numPage;

      const data = state.chatsPendings.slice(startIndex, endIndex);
      if (action.payload > totalPage) return;
      if (state.pageSplitting.length === 0) {
        state.pageSplitting = data;
      } else {
        data.forEach((item) => {
          if (!state.pageSplitting.some((ele) => ele._id.includes(item._id))) {
            state.pageSplitting = [...state.pageSplitting, item];
          }
        })
      }
    },
    // getChatByPage: (state, action: PayloadAction<number>) => {
    //   const searchPage = state.allChatsPage?.find(
    //     (elem) => elem.page === action.payload,
    //   );
    //   if (searchPage) {
    //     state.chatsByPage = searchPage;
    //   }
    // },
    // setChatsPage: (state) => {
    //   state.allChatsPage = [];
    //   const numPage = 6;
    //   const totalPage =
    //     state.chatsPendings && Math.ceil(state.chatsPendings.length / numPage);
    //   if (totalPage === 1 || totalPage === 0) {
    //     state.allChatsPage = [
    //       {
    //         chats: [...state.chatsPendings],
    //         page: 1,
    //       },
    //     ];
    //   }
    //   if (totalPage > 1) {
    //     const arrayPage = new Array(totalPage).fill(0).map((_val, i) => i + 1);
    //     if (state.allChatsPage.length < 1) {
    //       arrayPage.forEach((item) => {
    //         const resp = state.chatsPendings.slice(
    //           (item - 1) * numPage,
    //           (item - 1) * numPage + numPage,
    //         );
    //         state.allChatsPage = [
    //           {
    //             chats: resp,
    //             page: item,
    //           },
    //           ...state.allChatsPage,
    //         ];
    //       });
    //     }
    //   }
    // },
    removeOneChatPending: (state, action: PayloadAction<string>) => {
      state.chatsPendings = state.chatsPendings.filter(
        (chat) => chat._id !== action.payload,
      );
      state.pageSplitting = state.pageSplitting.filter(
        (chat) => chat._id !== action.payload,
      );
    },
  },
});

export const {
  setChatsPendings,
  setOneChatPending,
  removeOneChatPending,
  setloadingPending,
  setPageSplitting,
  setNewMessagePending,
  // setOneChatPage,
  // setChatsPage,
  // getChatByPage,
} = chatsPendingsToState.actions;
export default chatsPendingsToState.reducer;
