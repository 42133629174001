/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../../models/users/user';

interface IUserSlice {
  usersData: User[];
  isloading: boolean;
  error: string | null;
}

const initialState: IUserSlice = {
  usersData: [],
  isloading: false,
  error: null,
};

export const userManagementStore = createSlice({
  name: 'useQueryState',
  initialState,
  reducers: {
    setDataUser: (
      state: { usersData: User[] },
      action: PayloadAction<User[]>,
    ) => {
      state.usersData = action.payload;
    },

    setAgentsAvailableToTransfer: (
      state: { usersData: User[] },
      action: PayloadAction<User>,
    ) => {
      if (state.usersData.find((user) => user._id === action.payload._id)) {
        state.usersData = state.usersData.filter(
          (user) => user._id !== action.payload._id,
        );
      }
      if (
        !state.usersData.find((user) => user._id === action.payload._id) &&
        action.payload.status === 'AVAILABLE'
      ) {
        state.usersData = [action.payload, ...state.usersData];
      }
    },

    setUpdateUser: (state, action: PayloadAction<User>) => {
      const userExist = state.usersData?.findIndex(
        (item: User) => item._id === action.payload?._id,
      );
      if (userExist !== -1) {
        const cloneUser = [...state.usersData];
        cloneUser.splice(userExist, 1, action.payload);
        return {
          ...state,
          usersData: cloneUser,
        };
      }
      return { ...state };
    },
  },
});

export const { setDataUser, setUpdateUser, setAgentsAvailableToTransfer } =
  userManagementStore.actions;
export default userManagementStore.reducer;
