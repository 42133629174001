/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ICustomMesssage,
  IPropsCustomMessage,
} from '../../../models/setting/setting';

interface IConfigurationSlice {
  allPredefinedResponse: IPropsCustomMessage;
  messageObject: ICustomMesssage[];
  isloading: boolean;
  error: string | null;
}

const initialState: IConfigurationSlice = {
  allPredefinedResponse: {} as IPropsCustomMessage,
  messageObject: [],
  isloading: false,
  error: null,
};

export const PredefinedResponseStore = createSlice({
  name: 'predefinedResponseState',
  initialState,
  reducers: {
    setPredefinedResponse: (
      state: { allPredefinedResponse: IPropsCustomMessage },
      action: PayloadAction<IPropsCustomMessage>,
    ) => {
      state.allPredefinedResponse = action.payload;
    },
    setMessageObject: (state, action: PayloadAction<ICustomMesssage[]>) => {
      state.messageObject = action.payload;
    },
  },
});

export const { setPredefinedResponse, setMessageObject } =
  PredefinedResponseStore.actions;
export default PredefinedResponseStore.reducer;
